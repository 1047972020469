import React, { PropsWithChildren, ReactElement } from "react";
import useWindowWidth, { heroDesktopMinWidth } from "../../hooks/useWindowWidth";
import { graphql } from "gatsby"
import styles from "./Privacy.module.scss"
import Helmet from "../../components/Helmet";
import Hero from "../../components/shared/HeroStaticPages";
import { getSeoDescription, getSeoKeywords, getSeoTitle } from "../../shared/helpers";


interface ITermTexts {
    type: string, text: string, spans: any[]
}
const PrivacyPolicy = (props: PropsWithChildren<any>): ReactElement => {
    const data = JSON.parse(props.data.allPrismicPrivacyPolicyPage.edges[0].node.dataString);

    const isDesktop = useWindowWidth(heroDesktopMinWidth)
    const background = isDesktop ? data.hero_background?.url : data.mobile_background?.url || data.hero_background?.url

    const heroAltText = (): string => 
    {
        let altText = "";
        if (isDesktop && data.hero_alt_text?.length > 0) {
            altText = data.hero_alt_text[0].text;
        } else if (!isDesktop && data.hero_alt_text_mobile?.length > 0) {
            altText = data.hero_alt_text_mobile[0].text;
        }
        return altText;
    }

    return (
        <>
            <Helmet
                title={getSeoTitle(data, "Privacy policies")}
                description={getSeoDescription(data, "Privacy policies page")}
                keywords={getSeoKeywords(data, "")} 
            />
            {background && <Hero img={background} alt={heroAltText()}/>}
            <main className={`mainBox ${styles.mainWrapper}`}>
                <section className={styles.mainInformation}>
                    {data.privacy_title && <h2 className={styles.mainTitle}>{data.privacy_title[0].text}</h2>}
                    {data.privacy_introduction_title && <h3 className={styles.termSectionTitle}>{data.privacy_introduction_title[0].text}</h3>}
                    {data.privacy_introduction &&
                        data.privacy_introduction.map((introduction:ITermTexts, i :number) => (
                            <p className={styles.mainDescription} key={i}>{introduction.text}</p>
                        ))}
                </section>
                <section>
                    {data.privacy_section && data.privacy_section.map((information: { priv_title: Array<ITermTexts>, priv_information: Array<ITermTexts> }, i: number) => {
                        return <div key={i}>
                            <h3 className={styles.termSectionTitle}>{information.priv_title[0]?.text}</h3>

                            {information.priv_information.map((term: ITermTexts, i:number) => (
                                <p className={styles.termSectionDescription} key={i}>{term?.text}</p>
                            ))}

                        </div>
                    })}
                </section>

            </main>
        </>
    );
};

export const query = graphql`
    query {
        allPrismicPrivacyPolicyPage {
            edges {
                node {
                    dataString
                }
            }
        }
    }
`;
export default PrivacyPolicy;
